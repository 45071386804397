import React, {useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'

import Button from 'src/components/Button'
import ModalTemplate from 'src/components/ModalTemplate'

import {setUser} from 'src/actions/user'
import {
  setDefaultModal,
  setErrorModal,
  setLoading,
  setModal,
  setWarningModal,
} from 'src/actions/element'
import {isChild} from 'src/helpers/domHelper'
import sendRequest from 'src/helpers/service'
import {handleError} from 'src/helpers/errorHandler'
import {setWalletState} from 'src/actions/data'

import c from './codeModal.module.css'

import raffleRedirectIcon from 'src/assets/images/raffle/redirect.png'
import giftDetailTopIcon from 'src/assets/images/gift-detail/top.png'
import giftDetailArrowIcon from 'src/assets/images/gift-detail/arrow.png'

export default function CodeModal({onClose, navigate, data, openRtw}) {
  const dispatch = useDispatch()
  const [code, setCode] = useState('')
  const user = useSelector((state) => state.user)

  const logCodeEvent = (event, responseCode) => {
    window.logEvent({
      event,
      Category: 'Promotion Send',
      Action: window.location.pathname,
      PromoCode: code,
      UserID: user.CustomerID,
      promo_name: data.promoName,
      promo_id: responseCode.raffleId || data.promoId,
      // ...(data.isRtw && {campaign_name: 'roadtowin'}),
    })
  }

  const handleSuccessResponse = async (responseCode) => {
    const customerData = await sendRequest.post(
      'electra/api/promo/getcustomer',
      {
        CustomerID: user.CustomerID,
      }
    )
    dispatch(setUser(customerData[0]))
    dispatch(setWalletState(false))

    logCodeEvent('sendCode', responseCode)

    responseCode.code = code
    const modalType = responseCode.ClassicsPromo?.ParticipationStatus
      ? 'classics'
      : 'win'

    dispatch(
      setModal({
        type: modalType,
        data: {
          ...responseCode,
          promoName: data.promoName,
          page: data.page,
          // ...(data.isRtw && {rtwStep: data.rtwStep, rtwComplete: data.rtwComplete})
        },
        navigate,
        // openRtw: modalType === 'win' && data && data?.openRtw && data.openRtw,
      })
    )
  }

  const handleErrorResponse = (responseCode) => {
    logCodeEvent('sendCode_error', responseCode)

    if (responseCode.ResponseCode === 3) {
      dispatch(
        setDefaultModal({
          icon: raffleRedirectIcon,
          title: 'Çekilişe Katıl',
          message: responseCode.Message,
          button: 'TAMAM',
        })
      )
    } else {
      dispatch(
        setErrorModal({
          message: responseCode.Message,
        })
      )
    }
  }

  const handleSubmit = async () => {
    try {
      if (code.length < 6) {
        logCodeEvent('sendCode_error', {ResponseCode: 'Eksik Kod'})

        dispatch(
          setWarningModal({
            message:
              code.length === 0 ? 'Lütfen kod giriniz.' : 'Eksik kod girdiniz.',
            button: 'TAMAM',
          })
        )
        return
      }

      const eventData = {
        event: 'entercode',
        UserID: user.CustomerID,
      }

      dispatch(setLoading(true))
      const codeRes = await sendRequest.post(
        'electra/api/promo/checkStickcode',
        {
          CustomerID: user.CustomerID,
          StickCode: code,
        }
      )

      eventData.pin_code = code
      eventData.ice_cream_code_product = codeRes.ProductName
      eventData.ice_cream_code_product_id = codeRes.brandCode
      eventData.point_earned = codeRes.Point

      if (codeRes.GiftTypeID) {
        eventData.gift_type = codeRes.GiftTypeID
        eventData.gift_name = codeRes.Title
        eventData.gift_id = codeRes.GiftID
        eventData.description = codeRes.Description
      }

      if (codeRes[0].ResponseCode === 0) {
        eventData.result = 'success'
        await handleSuccessResponse(codeRes[0])
      } else {
        if (codeRes.ResponseCode === 1) {
          eventData.result = 'already_redeemed'
        } else if (codeRes.ResponseCode === 2) {
          eventData.result = 'wrong_code'
        }
        handleErrorResponse(codeRes[0])
      }

      window.logEvent(eventData)

      setCode('')
    } catch (error) {
      setCode('')
      dispatch(setLoading(false))
      handleError(error, dispatch)

      logCodeEvent('sendCode_error', {
        ResponseCode: error.response?.data?.[0]?.Message || 'Unknown Error',
      })
    } finally {
      dispatch(setLoading(false))
    }
  }

  return (
    <ModalTemplate
      className={c.modal}
      contentClass={c.content}
      closeClass={c.close}
      onClose={onClose}
    >
      <div
        id='code-enter-modal-content'
        className={c.container}
        data-close={true}
        onClick={(e) => {
          if (
            window.isMobile() &&
            (!isChild(e.target, 'code-enter-modal-content') ||
              e.target.dataset.close)
          ) {
            onClose()
          }
        }}
      >
        <img
          src={giftDetailTopIcon}
          className={c.coverImage}
          alt='giftDetailTopIcon'
        />

        <div className={c.desktopText}>
          Kampanyaya katılmak için kodu gir puan kazan!
        </div>

        <input
          placeholder='Kodu girin'
          className={c.input}
          value={code}
          onChange={(e) => {
            if (e.target.value.length < 14) setCode(e.target.value)
          }}
        />

        <Button className={c.submitButton} dark onClick={handleSubmit}>
          KODU GÖNDER
          <img src={giftDetailArrowIcon} alt='giftDetailArrowIcon' />
        </Button>
      </div>
    </ModalTemplate>
  )
}
